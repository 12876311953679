// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-general-maintenance-boarding-up-js": () => import("./../src/pages/services/general-maintenance/boarding-up.js" /* webpackChunkName: "component---src-pages-services-general-maintenance-boarding-up-js" */),
  "component---src-pages-services-general-maintenance-draught-proofing-js": () => import("./../src/pages/services/general-maintenance/draught-proofing.js" /* webpackChunkName: "component---src-pages-services-general-maintenance-draught-proofing-js" */),
  "component---src-pages-services-general-maintenance-index-js": () => import("./../src/pages/services/general-maintenance/index.js" /* webpackChunkName: "component---src-pages-services-general-maintenance-index-js" */),
  "component---src-pages-services-general-maintenance-interior-maintenance-js": () => import("./../src/pages/services/general-maintenance/interior-maintenance.js" /* webpackChunkName: "component---src-pages-services-general-maintenance-interior-maintenance-js" */),
  "component---src-pages-services-general-maintenance-new-builds-js": () => import("./../src/pages/services/general-maintenance/new-builds.js" /* webpackChunkName: "component---src-pages-services-general-maintenance-new-builds-js" */),
  "component---src-pages-services-general-maintenance-waterproofing-js": () => import("./../src/pages/services/general-maintenance/waterproofing.js" /* webpackChunkName: "component---src-pages-services-general-maintenance-waterproofing-js" */),
  "component---src-pages-services-glazing-services-bathrooms-and-showers-js": () => import("./../src/pages/services/glazing-services/bathrooms-and-showers.js" /* webpackChunkName: "component---src-pages-services-glazing-services-bathrooms-and-showers-js" */),
  "component---src-pages-services-glazing-services-doors-js": () => import("./../src/pages/services/glazing-services/doors.js" /* webpackChunkName: "component---src-pages-services-glazing-services-doors-js" */),
  "component---src-pages-services-glazing-services-double-glazing-js": () => import("./../src/pages/services/glazing-services/double-glazing.js" /* webpackChunkName: "component---src-pages-services-glazing-services-double-glazing-js" */),
  "component---src-pages-services-glazing-services-index-js": () => import("./../src/pages/services/glazing-services/index.js" /* webpackChunkName: "component---src-pages-services-glazing-services-index-js" */),
  "component---src-pages-services-glazing-services-mirrors-js": () => import("./../src/pages/services/glazing-services/mirrors.js" /* webpackChunkName: "component---src-pages-services-glazing-services-mirrors-js" */),
  "component---src-pages-services-glazing-services-repairs-js": () => import("./../src/pages/services/glazing-services/repairs.js" /* webpackChunkName: "component---src-pages-services-glazing-services-repairs-js" */),
  "component---src-pages-services-glazing-services-security-js": () => import("./../src/pages/services/glazing-services/security.js" /* webpackChunkName: "component---src-pages-services-glazing-services-security-js" */),
  "component---src-pages-services-glazing-services-shop-fronts-js": () => import("./../src/pages/services/glazing-services/shop-fronts.js" /* webpackChunkName: "component---src-pages-services-glazing-services-shop-fronts-js" */),
  "component---src-pages-services-glazing-services-single-glazing-js": () => import("./../src/pages/services/glazing-services/single-glazing.js" /* webpackChunkName: "component---src-pages-services-glazing-services-single-glazing-js" */),
  "component---src-pages-services-glazing-services-splashbacks-js": () => import("./../src/pages/services/glazing-services/splashbacks.js" /* webpackChunkName: "component---src-pages-services-glazing-services-splashbacks-js" */),
  "component---src-pages-services-glazing-services-upvc-js": () => import("./../src/pages/services/glazing-services/upvc.js" /* webpackChunkName: "component---src-pages-services-glazing-services-upvc-js" */),
  "component---src-pages-services-index-js": () => import("./../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-painting-and-decorating-artexing-js": () => import("./../src/pages/services/painting-and-decorating/artexing.js" /* webpackChunkName: "component---src-pages-services-painting-and-decorating-artexing-js" */),
  "component---src-pages-services-painting-and-decorating-commercial-decorating-services-js": () => import("./../src/pages/services/painting-and-decorating/commercial-decorating-services.js" /* webpackChunkName: "component---src-pages-services-painting-and-decorating-commercial-decorating-services-js" */),
  "component---src-pages-services-painting-and-decorating-index-js": () => import("./../src/pages/services/painting-and-decorating/index.js" /* webpackChunkName: "component---src-pages-services-painting-and-decorating-index-js" */),
  "component---src-pages-services-painting-and-decorating-new-build-decorating-js": () => import("./../src/pages/services/painting-and-decorating/new-build-decorating.js" /* webpackChunkName: "component---src-pages-services-painting-and-decorating-new-build-decorating-js" */),
  "component---src-pages-services-painting-and-decorating-residential-exterior-decorating-js": () => import("./../src/pages/services/painting-and-decorating/residential-exterior-decorating.js" /* webpackChunkName: "component---src-pages-services-painting-and-decorating-residential-exterior-decorating-js" */),
  "component---src-pages-services-painting-and-decorating-residential-interior-decorating-js": () => import("./../src/pages/services/painting-and-decorating/residential-interior-decorating.js" /* webpackChunkName: "component---src-pages-services-painting-and-decorating-residential-interior-decorating-js" */),
  "component---src-pages-services-painting-and-decorating-wallpapering-js": () => import("./../src/pages/services/painting-and-decorating/wallpapering.js" /* webpackChunkName: "component---src-pages-services-painting-and-decorating-wallpapering-js" */),
  "component---src-pages-services-plastering-and-rendering-commercial-plastering-js": () => import("./../src/pages/services/plastering-and-rendering/commercial-plastering.js" /* webpackChunkName: "component---src-pages-services-plastering-and-rendering-commercial-plastering-js" */),
  "component---src-pages-services-plastering-and-rendering-exterior-rendering-js": () => import("./../src/pages/services/plastering-and-rendering/exterior-rendering.js" /* webpackChunkName: "component---src-pages-services-plastering-and-rendering-exterior-rendering-js" */),
  "component---src-pages-services-plastering-and-rendering-flood-restoration-js": () => import("./../src/pages/services/plastering-and-rendering/flood-restoration.js" /* webpackChunkName: "component---src-pages-services-plastering-and-rendering-flood-restoration-js" */),
  "component---src-pages-services-plastering-and-rendering-index-js": () => import("./../src/pages/services/plastering-and-rendering/index.js" /* webpackChunkName: "component---src-pages-services-plastering-and-rendering-index-js" */),
  "component---src-pages-services-plastering-and-rendering-residential-plastering-js": () => import("./../src/pages/services/plastering-and-rendering/residential-plastering.js" /* webpackChunkName: "component---src-pages-services-plastering-and-rendering-residential-plastering-js" */)
}

